<template>
  <img alt="Yida logo" src="@/assets/logo.png" class="logo" />
  <h2>深圳亿达国际供应链</h2>
  <!-- 单号输入框 -->
  <!-- <textarea  v-model="customerNos" placeholder="请输入客户单号(多个支持以英文逗号 / 空格 / 换行符分隔)" rows="4" cols="50" wrap="soft" style="overflow-y: scroll;" /> -->
  <input type="text"  v-model="customerNos" placeholder="请输入客户单号(多个支持以英文逗号 / 空格分隔; 单次不能超过10个)" style="width:500px; height: 40px;" enterkey="enter" @keyup.enter="printContent" />
  <div>
    <!-- <button id="printContent" @click="printContent" style="margin-bottom: 20px; margin-top: 20px; margin-right: 5px;">获取信息</button> -->
    <button id="printContent" @click="printContent" style="margin-bottom: 20px; margin-top: 20px;">打印</button>
    <!-- 批量打印区域 -->
    <div width="100%" style="align-items: center;">
      <!-- <div v-for="(item, index) in printItems" :key="index" :id="'printArea' + index" class="printAreaStyle">
        <table>
          <tr>
            <th>UPS单号</th>
            <td>{{item.zhuanDanHao}}</td>
          </tr>
          <tr>
            <th>产品类型名称</th>
            <td>{{item.invoiceModel.invoiceItemModelList[0].nameCn}}(弱磁)</td>
          </tr>
          <tr>
            <th>数量</th>
            <td>{{item.invoiceModel.invoiceItemModelList[0].quantity}}</td>
          </tr>
          <tr>
            <th>毛重KG</th>
            <td>{{item.receiptWeight}}</td>
          </tr>
          <tr>
            <th>实重KG</th>
            <td>{{item.receiptWeight}}</td>
          </tr>
        </table>
      </div> -->
      <div id="printArea">
        <div v-for="(item, index) in printItems" :key="index" :id="'printArea-' + index">
          <div v-for="(unit, idx) in item.apiUnitModelList" :key="idx" :id="'printArea-' + index + '-' + idx" class="printAreaStyle">
            <div><svg :id="'barcode-' + index + '-' + idx"></svg></div>
            <div style="margin-left: 15px;">
              <span>{{unit.systemNo}}</span>
              <span style="margin-left: 100px;">{{idx + 1}}/{{item.apiUnitModelList.length}}</span>
            </div>
            <div style="margin-left: 15px;">
              <span style="font-size: 20px; font-weight: bold;">{{item.clientCode}}</span>
              <span style="margin-left: 50px; font-size: 20px; font-weight: bold;">{{unit.keHuDanHao}}</span>
            </div>
            <!-- <svg ref="barcodeList"></svg> -->
            <!-- 换页打印 -->
            <!-- <p style="page-break-after: always"></p> -->
          </div>
          <!-- <p style="page-break-after: always"></p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { syncQueryWaybillByOrderNos } from '@/components/domain/apis/ProxyItdida.js';
import JsBarcode from 'jsbarcode';
export default {
  data() {
    return {
      customerNos:'',
      printItems: [
        // { UPS单号: '123456789', 产品类型名称: '弱磁', 数量: 1, 毛重KG: 10, 实重KG: 8 },
        // { UPS单号: '1234567890', 产品类型名称: '弱磁', 数量: 2, 毛重KG: 11, 实重KG: 9 },
        // ...更多数据
      ]
    };
  },
  methods: {
    // 按英文逗号或者换行符分隔字符串，返回数组列表
    // 考虑多个空格、制表符等情况，使用正则表达式分割字符串
    // 注意：正则表达式中，\s 匹配任何空白字符，包括空格、制表符、换行符等
    // 因此，\s+ 匹配多个空白字符，包括多个空格、制表符、换行符等

    splitCustomerNos(str) {
      // 替换所有\s 为英文逗号
      str = str.replace(/\s+/g, ',');
      // console.log("1: " + str);
      // 去掉空行
      // str = str.replace(/^\s+|\s+$/g, ',');
      // 多个英文逗号替换成一个英文逗号
      str = str.replace(/,+/g, ',');
      // console.log("2: " + str);

      str.split(/[\s+,，]/g);
      // console.log("3: " + str);
      // 去掉前后的英文逗号
      str = str.replace(/^,|,$/g, '');
      // console.log("4: " + str);
      // 按英文逗号分隔字符串，返回数组列表
      str = str.split(/[\s+,，]/g);
      return str;
    },
    printPreview() {
      this.printItems.forEach((item, index) => {
        const printContent = document.getElementById('printArea-' + index).cloneNode(true); // 克隆节点，包括其样式;
        const originalContent = document.body.innerHTML;
        document.body.innerHTML = printContent;
        window.print();
        document.body.innerHTML = originalContent;
      });
    },
    printContent() {
      // 如果没有选择客户单号，则提示用户
      if (this.customerNos.length === 0) {
        alert('请填写客户单号');
        return;
      }
      // const originalContent = document.body.innerHTML;
      // var printWindowContent = '';
      const beforeCustomerNos = this.splitCustomerNos(this.customerNos);
      // 最多不能超过10个单号
      if (beforeCustomerNos.length > 10) {
        alert('最多只能选择10个单号');
        return;
      }
      this.printItems = syncQueryWaybillByOrderNos(beforeCustomerNos);
      if (this.printItems.length === 0) {
        alert('没有查询到相关信息');
        return;
      }
      // 过滤printItems，只保留有效数据
      this.printItems = this.printItems.filter(item => item.xiTongDanHao && item.xiTongDanHao.length > 0
        && item.keHuDanHao && item.keHuDanHao.length > 0
      );
      // 对比过滤前后的单号列表，如果有变化，则提示被丢弃的单号，并提示用户
      
      const afterCustomerNos = this.printItems.map(item => item.kehuDanHao);
      const diffCustomerNos = beforeCustomerNos.filter(item => !afterCustomerNos.includes(item.keHuDanHao));
      if (diffCustomerNos.length > 0) {
        alert(`以下客户单号缺失相关信息：${diffCustomerNos.join(',')}`);
        return;
      }
      this.$nextTick(() => {
        this.printItems.forEach((item, index) => {
          item.apiUnitModelList.forEach((unit, idx) => {
          // 获取canvas元素
          const canvasElement = document.getElementById(`barcode-${index}-${idx}`);
          // 确保canvas元素存在
          if (canvasElement) {
            // // 获取2D渲染上下文
            // const context = canvasElement.getContext('2d');
            // // 清除canvas
            // context.clearRect(0, 0, canvasElement.width, canvasElement.height);
            // 生成新的条形码
            JsBarcode(canvasElement, unit.systemNo, {
              format: "CODE128", // 指定条形码的格式
              displayValue: false, // 是否在条形码下方显示文本
              fontSize: 20, // 字体大小
            });
          }
          });
        });
      });
      // 等待 Vue 完成 DOM 更新
      this.$nextTick(() => {
        // this.printItems.forEach((item, index) => {
        //   // 获取canvas元素
        //   const canvasElement = document.getElementById(`barcode-${index}`);
        //   // 确保canvas元素存在
        //   if (canvasElement) {
        //     // 获取2D渲染上下文
        //     const context = canvasElement.getContext('2d');
        //     // 清除canvas
        //     context.clearRect(0, 0, canvasElement.width, canvasElement.height);
        //     // 生成新的条形码
        //     JsBarcode(canvasElement, item.xiTongDanHao, {
        //       format: "CODE128", // 指定条形码的格式
        //       displayValue: false, // 是否在条形码下方显示文本
        //       fontSize: 20, // 字体大小
        //     });
        //   }
        // });
        
        const printStyles = `
        @media print {
          @page {
            size: 3.94in 5.91in;
            margin: 0;
          }
          
          html, body {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            overflow: visible;
          }
          
          .printAreaStyle {
            width: 100%;
            height: 100%;
            page-break-before: always;
          }
          
        }
      `;
      
      // 创建一个新的窗口
      const printWindow = window.open('', '_blank');
      const printDocument = printWindow.document;
      // 设置新窗口的内容
      printDocument.write('<html><head><title>标签打印</title>');
      // 引入样式
      printDocument.write('<style type="text/css">');
      printDocument.write(printStyles);
      printDocument.write('</style>');
      // printDocument.write('<link rel="stylesheet" type="text/css" href="/styles/print.css">');
      printDocument.write('</head><body>');
      this.printItems.forEach((item, index) => {
        item.apiUnitModelList.forEach((unit, idx) => {
          const printContent = document.getElementById('printArea-' + index + "-" + idx).cloneNode(true); // 克隆节点，包括其样式;
          printDocument.body.appendChild(printContent);
        });
      });

      // printDocument.write(printWindowContent);
      printDocument.write('</body></html>');
      // 完成文档写入
      printDocument.close();
      // 打印新窗口
      printWindow.print();
      // 关闭新窗口
      // 打印完成后关闭新窗口
      printWindow.addEventListener('afterprint', () => {
        printWindow.close();
        this.customerNos = '';
        this.printItems = [];
      });
      // printWindow.close();
      // document.body.innerHTML = originalContent;
      });
    }
  }
};
</script>

<style>
  /* print.css */
  /* @media print {
   @page {
     size: 3.94in 5.91in;
     margin: 0;
   }
  
   html, body {
     width: 100%;
     height: 100%;
     margin: 0;
     padding: 0;
     overflow: visible;
   }
  
   .printAreaStyle {
     width: 100%;
     height: 100%;
     page-break-before: always;
   }
  
   table {
     width: 100%;
     border-collapse: collapse;
   }
  
   th {
     width: 40%;
     border: 1px solid black;
     padding: 5px;
     text-align: left;
   }

   td {
     width: 60%;
     border: 1px solid black;
     padding: 5px;
     text-align: left;
   }
  
  }  */

  table {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    /* align-items: center; */
    border-collapse: collapse;
    page-break-before: always;
   }
  
   th {
     width: 40%;
     border: 1px solid black;
     padding: 5px;
     text-align: left;
   }

   td {
     width: 60%;
     border: 1px solid black;
     padding: 5px;
     text-align: left;
   }

   button {
    width: 100px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }

  button:hover {
    background-color: #0056b3;
  }

</style>