// import { createApp } from 'vue'
// import App from './App.vue'

// const app = createApp(App)
// app.mount('#app');


import { createApp } from 'vue';
// import Vue from 'vue';
// import Vuex from 'vuex'; 
import App from './App.vue';
import router from './router';
import store from './store'; // 确保您有一个 store.js 文件
import print from 'vue-print-nb';

// Vue.use(Vuex);

// new Vue({
//   router,
//   store, // 将 Vuex Store 注入到 Vue 实例中
//   render: h => h(App),
// }).$mount('#app');

createApp(App)
  .use(router)
  .use(store)
  .use(print)
  .mount('#app');


